@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');



header {
    width: 100%;
    height: 100px;
    color:white;
}

.about {
    width: 100%;
    height: auto;
    background-color: white;
}

.landing-page {
    background:url(./component/assets/Images/myself.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.home {
    color:white;
}

.garis {
    width: 300px;
    height: 3px;
    background-color:white;
    margin-left:auto;
    margin-right: auto;
    position: relative;
    animation: garisan 1.5s forwards;
}

.hi {
    font-size: 25px;
    font-family: 'Quicksand', sans-serif;
    margin-top:290px;
    font-style: italic;
    
}

.my_name {
    position: relative;
    font-size: 64px;
    font-family: 'Oswald', sans-serif;
    text-align: center;
}

.navbar {
    font-family: 'Poppins', sans-serif;
    border:white;
}

.img {
    top: 0px;
    position: absolute;
    margin-left:auto;
    margin-right:auto;
}

.organization {
    width: 50%;
    height: auto;
    
}

.link {
    color:white;
}

.link:hover {
    color:darkgoldenrod;
}
.border-b-2 {
   border-bottom-width:1px;
   border-color:#ffffff00;
   transition: border-color .25s ease-out .25s;
}

.border-b-2:hover{
    border-color:white;
}

.oswald {
    font-family: 'Oswald', sans-serif;  
}


@media only screen and (min-width:900px){

    @keyframes garisan {
        0% {width: 0;}
        100% {width: 300px;}
    }
}

@media only screen and (max-width: 812px){
    .hi {
        margin-top:100px;
    }
}


@media only screen and (max-width: 600px){


    @keyframes garisan {
        0% {width: 0;}
        100% {width: 200px;}
    }
    .garis {
        width:200px;
        height:2px;
    }
    .my_name {
        font-size:40px;
    }

    .hi {
        font-size: 20px;
        margin-top:250px;
    }

    header {
        display:block;
        height: 150px;
    }
    .navbar {
        top:100px;
        position:absolute;
        color:black;
        left:10px;
    }

    img {
        position: absolute;
        margin-left:auto;
        margin-right:auto;
        display: block;
    }
    .link {
        margin: 0 auto;
    }
    .about_background {
        text-align: justify;
        margin: 0 auto;
    }
    .aboutheader {
        text-align: center;
    }
    .flex {
        display: block;
    }
    .last_organization {
        text-align: center;
    }
    .isi_organisasi {
        margin:0 auto;
        display: block;
    }
    .organization {
        margin-left: 7rem;
        margin-right: 7rem;
    }
    .starting {
        margin-left: 3.5rem;
        margin-right: 3.5rem;
    }
}

@media only screen and (max-width: 360px){
    .link {
        margin: 0 auto;
    }
    .about_background {
        margin: 0 auto;
        text-align: justify;
    }
    .aboutheader {
        text-align: center;
    }
    .flex {
        display: block;
    }
    .last_organization {
        text-align: center;
    }
    .isi_organisasi {
        margin:0 auto;
        display: block;
    }
    .organization {
        margin-left: 7rem;
        margin-right: 7rem;
    }
    .starting {
        margin-left: 3.5rem;
        margin-right: 3.5rem;
    }
}


